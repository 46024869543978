<template>
  <svg :width="size" :height="size" :viewBox="`0 0 24 24`">
    <path
      d="M20.3878 9.86625L12.8878 2.78812L12.8794 2.78062C12.6384 2.55993 12.3235 2.43751 11.9967 2.43751C11.67 2.43751 11.3551 2.55993 11.1141 2.78062L11.1056 2.78812L3.60564 9.86625C3.47298 9.98976 3.36734 10.1394 3.29538 10.3058C3.22342 10.4721 3.18669 10.6516 3.18751 10.8328V19.5C3.18751 19.8481 3.32579 20.1819 3.57194 20.4281C3.81808 20.6742 4.15192 20.8125 4.50001 20.8125H9.00001C9.34811 20.8125 9.68195 20.6742 9.92809 20.4281C10.1742 20.1819 10.3125 19.8481 10.3125 19.5V15C10.3125 14.9503 10.3323 14.9026 10.3674 14.8674C10.4026 14.8323 10.4503 14.8125 10.5 14.8125H13.5C13.5497 14.8125 13.5974 14.8323 13.6326 14.8674C13.6678 14.9026 13.6875 14.9503 13.6875 15V19.5C13.6875 19.8481 13.8258 20.1819 14.0719 20.4281C14.3181 20.6742 14.6519 20.8125 15 20.8125H19.5C19.8481 20.8125 20.1819 20.6742 20.4281 20.4281C20.6742 20.1819 20.8125 19.8481 20.8125 19.5V10.8328C20.8125 10.6511 20.7747 10.4713 20.7016 10.3049C20.6285 10.1386 20.5217 9.98918 20.3878 9.86625ZM19.6875 19.5C19.6875 19.5497 19.6678 19.5974 19.6326 19.6326C19.5974 19.6677 19.5497 19.6875 19.5 19.6875H15C14.9503 19.6875 14.9026 19.6677 14.8674 19.6326C14.8323 19.5974 14.8125 19.5497 14.8125 19.5V15C14.8125 14.6519 14.6742 14.3181 14.4281 14.0719C14.1819 13.8258 13.8481 13.6875 13.5 13.6875H10.5C10.1519 13.6875 9.81808 13.8258 9.57194 14.0719C9.32579 14.3181 9.18751 14.6519 9.18751 15V19.5C9.18751 19.5497 9.16776 19.5974 9.1326 19.6326C9.09743 19.6677 9.04974 19.6875 9.00001 19.6875H4.50001C4.45029 19.6875 4.40259 19.6677 4.36743 19.6326C4.33227 19.5974 4.31251 19.5497 4.31251 19.5V10.8328C4.31246 10.8067 4.31785 10.7809 4.32834 10.757C4.33883 10.7331 4.3542 10.7117 4.37345 10.6941L4.38189 10.6866L11.8763 3.61031C11.9104 3.58051 11.9542 3.56409 11.9995 3.56409C12.0449 3.56409 12.0887 3.58051 12.1228 3.61031L19.6181 10.6875L19.6256 10.695C19.6451 10.7125 19.6606 10.7339 19.6713 10.7578C19.6819 10.7817 19.6875 10.8076 19.6875 10.8337V19.5Z"
      :fill="color"
    />
  </svg>
</template>

<script>
import IconMixin from "../../mixins/IconMixin";

export default {
  mixins: [IconMixin],
};
</script>
