<template>
  <div id="app">
    <v-app id="inspire">
      <v-app-bar
        absolute
        :clipped-left="$vuetify.breakpoint.lgAndUp"
        fixed
        app
        color="white"
        elevation="0"
        style="z-index: 50"
        dark
      >
        <v-toolbar-title class="ml-0 pl-4 pr-9 black--text d-flex align-center">
          <img src="/icon.png" height="48" />
          <span
            class="hidden-sm-and-down ml-2"
            style="font-size: 26px; font-weight: 700"
            >EPS</span
          >
        </v-toolbar-title>
        <v-toolbar-items
          class="nav-items"
          v-if="!$vuetify.breakpoint.smAndDown"
        >
          <v-btn
            class="black--text d-flex align-center nav-menu"
            :ripple="false"
            text
            to="/home"
          >
            <HomeIcon :color="'#787486'" class="mr-2 img-icon" />
            Dashboard
          </v-btn>
          <v-btn
            v-if="this.user.role == 'admin'"
            class="black--text d-flex align-center nav-menu"
            :ripple="false"
            text
            to="/employees"
          >
            <UsersIcon :color="'#787486'" class="mr-2 img-icon" />
            Members
          </v-btn>
          <v-btn
            class="black--text d-flex align-center nav-menu"
            :ripple="false"
            text
            to="/projects"
          >
            <ProjectsIcon :color="'#787486'" :size="20" class="mr-2 img-icon" />
            Projects
          </v-btn>

          <v-btn
            class="black--text d-flex align-center nav-menu"
            :ripple="false"
            text
            to="/timeSheet"
          >
            <ProjectsIcon :color="'#787486'" :size="20" class="mr-2 img-icon" />
            Timesheets
          </v-btn>
        </v-toolbar-items>

        <v-spacer></v-spacer>
        <v-menu offset-y v-if="$vuetify.breakpoint.smAndDown">
          <template v-slot:activator="{ on }">
            <v-btn color="primary" icon dark v-on="on">
              <v-icon>mdi mdi-format-line-weight</v-icon>
            </v-btn>
          </template>
          <v-sheet>
            <v-btn
              class="black--text d-flex align-center"
              :ripple="false"
              text
              to="/home"
            >
              <HomeIcon :color="'#787486'" class="mr-2 img-icon" />
              Dashboard
            </v-btn>
            <v-btn
              v-if="this.user.role == 'admin'"
              class="black--text d-flex align-center"
              :ripple="false"
              text
              style="justify-content: flex-start"
              to="/employees"
            >
              <UsersIcon :color="'#787486'" class="mr-2 img-icon" />
              Members
            </v-btn>
            <v-btn
              class="black--text d-flex align-center"
              :ripple="false"
              text
              style="justify-content: flex-start"
              to="/projects"
            >
              <ProjectsIcon
                :color="'#787486'"
                :size="20"
                class="mr-2 img-icon"
              />
              Projects
            </v-btn>

            <v-btn
              class="black--text d-flex align-center"
              :ripple="false"
              text
              to="/timeSheet"
            >
              <ProjectsIcon
                :color="'#787486'"
                :size="20"
                class="mr-2 img-icon"
              />
              Timesheets
            </v-btn>
          </v-sheet>
        </v-menu>
        <v-btn
          color="secondary"
          icon
          small
          class="mr-3"
          @click="showNotifications = true"
        >
          <v-badge
            :color="unReadNotification > 0 ? 'amber accent-4' : ''"
            overlap
            :content="unReadNotification"
          >
            <v-icon> mdi-bell-outline </v-icon>
          </v-badge>
        </v-btn>

        <v-menu
          offset-y
          bottom
          origin="top center"
          class="user-menu"
          content-class="user-menu"
          transition="scale-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" class="rounded-circle">
              <v-avatar
                size="30"
                v-if="user && user.profile_url != null"
                v-bind="attrs"
                v-on="on"
                class="cursor-pointer ml-3"
              >
                <img
                  :src="
                    user.profile_url
                      ? user.profile_url
                      : 'icons/default_profile.png'
                  "
                  :alt="user"
                  style="width: auto"
                />
              </v-avatar>
              <v-avatar
                v-else
                color="success"
                class="black--text cursor-pointer ml-3"
                size="30"
                v-bind="attrs"
                v-on="on"
              >
                <span class="fontsize-11">{{ user.name.charAt(0) }}</span>
              </v-avatar>

              <span style="font-size: 15px" class="ml-2 black--text"
                >{{ user.name }}
                <v-icon class="black--text"> mdi-chevron-down </v-icon>
              </span>
            </div>
          </template>
          <v-list dense>
            <v-list-item-group color="primary">
              <v-list-item
                v-for="(item, index) in profileDropdownItems"
                :key="index"
                color="primary"
                @click="redirect(item.href)"
              >
                <v-list-item-icon>
                  <v-icon>
                    {{ item.icon }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title class="poniter">
                  {{ item.title }}
                </v-list-item-title>
                <hr />
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-app-bar>
      <v-main style="background: #f2f2f2">
        <Drawer
          v-model="showNotifications"
          @close="showNotifications = false"
          :fullscreen="true"
          id="notification-drawer"
        >
          <template v-slot:title> Notifications </template>
          <template v-slot:header>
            <div class="full-width mt-5">
              <v-divider />
            </div>
            <div class="full-width mt-5 d-flex">
              <v-switch
                top
                class="mt-0"
                v-model="unReadNotificationList"
                @change="getUnReadNotification()"
                label="Unread"
                hide-details
              ></v-switch>
              <v-spacer></v-spacer>
              <v-btn small color="primary" @click="readAllNotification()"
                >Read All</v-btn
              >
            </div>
          </template>
          <template v-slot:content>
            <v-list max-width="378px" class="py-0">
              <v-list-item
                v-for="(notification, index) in notificationArray"
                :key="notification.id"
                @click.stop="redirectToNotificationContent(notification)"
                color="primary"
                :class="`pl-2 notification-item ${
                  notification.is_read == '0' ? 'unreaded' : ''
                }`"
                dense
                :tabindex="index"
              >
                <v-list-item-avatar>
                  <Avatar
                    :color="notification.employee?.color"
                    :intials="notification.employee?.intials"
                    :profile_url="notification.employee?.profile_url"
                    size="40"
                  />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    <p
                      v-html="notification.message"
                      class="subtitle-2 text-truncate mb-0"
                    />
                  </v-list-item-title>
                  <v-list-item-subtitle
                    class="secondary--text font-weight-normal text-caption"
                  >
                    {{ getHumanReadableDate(notification.created_at) }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <div
              v-if="notificationArray.length == 0"
              class="secondary--text text-center my-5"
            >
              No Notifications
            </div>
            <div
              v-if="notificationArray.length < totalnotification"
              class="text-center mt-4 mb-1"
            >
              <v-btn @click.stop="moreNotifiction()" color="primary" depressed>
                load more
              </v-btn>
            </div>
          </template>
        </Drawer>
        <router-view @updateProfilePic="updateProfilePic"></router-view>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import WsSubscriptions from "../../WsSubscriptions";
import { mapMutations, mapState } from "vuex";
import ProjectsIcon from "../../assets/icons/ProjectsIcon.vue";
import HomeIcon from "../../assets/icons/HomeIcon.vue";
import UsersIcon from "../../assets/icons/UsersIcon.vue";
import Drawer from "../common/basic/Drawer.vue";
import Avatar from "../common/basic/Avatar.vue";
import moment from "moment";

export default {
  async created() {
    await WsSubscriptions();
  },
  data: () => ({
    dialog: false,
    drawer: true,
    notificationArray: [],
    notificationPage: 1,
    unReadNotification: 0,
    totalnotification: 0,
    unReadNotificationList: false,

    profileDropdownItems: [
      { title: "Profile", href: "/profile", icon: "mdi-account" },
      { title: "Change Password", href: "/changePassword", icon: "mdi-lock" },
      { title: "Setting", href: "/setting", icon: "mdi-cog" },
      {
        title: "Log Out",
        href: "logout",
        method: "logout",
        icon: "mdi-logout",
      },
    ],
    items: [
      {
        icon: "mdi-home",
        text: "Dashboard",
        href: "/home",
      },
      {
        icon: "mdi-account-group",
        text: "Employees",
        href: "/employees",
      },
      {
        icon: "mdi-chart-box-outline",
        text: "Projects",
        href: "/projects",
      },
    ],
    user: {
      profile: null,
      name: "",
    },
    showNotifications: false,
  }),
  components: {
    UsersIcon,
    ProjectsIcon,
    HomeIcon,
    Drawer,
    Avatar,
  },
  computed: {
    ...mapState("projects", ["syncProjects"]),
    ...mapState("employees", ["syncEmployees"]),
    ...mapState("tasks", ["syncProject"]),
  },
  methods: {
    ...mapMutations("projects", ["setSyncProjects"]),
    ...mapMutations("employees", ["setSyncEmployees"]),
    ...mapMutations("tasks", ["setSyncProject", "setPriorities"]),
    ...mapMutations("search", ["setSearch"]),
    redirect(href) {
      if (href == "logout") {
        this.logout();
      } else {
        this.$router.push(href);
      }
    },
    getNotification() {
      let _self = this;
      _self.$axios
        .get(
          `notification_list?page=${this.notificationPage}&unReadNotificationList=${this.unReadNotificationList}`
        )
        .then((res) => {
          if (res.data.success) {
            if (this.notificationPage > 1) {
              this.notificationArray = [
                ...this.notificationArray,
                ...res.data.notificationList.data,
              ];
            } else {
              this.notificationArray = res.data.notificationList.data;
            }
            this.unReadNotification = res.data.unReadcount;
            this.totalnotification = res.data.notificationList.total;
          } else {
            console.log("something went wrong with notification");
          }
        });
    },
    getUnReadNotification() {
      this.notificationArray = [];
      this.notificationPage = 1;
      this.getNotification();
    },
    moreNotifiction() {
      this.notificationPage = this.notificationPage + 1;
      this.getNotification();
    },
    readNotification(notification) {
      let id = notification.id;

      let _self = this;
      _self.$axios.put(`notification_update/${id}?is_read=1`).then((res) => {
        if (res.data.success) {
          this.notificationArray.map((item) => {
            if (item.id == id) {
              item.is_read = 1;
            }
            return item;
          });
          this.unReadNotification = res.data.unReadcount;
        } else {
          console.log("Not complete");
        }
      });
    },
    readAllNotification() {
      this.$axios.put(`read_all_notification`).then((res) => {
        if (res.data.success) {
          this.getUnReadNotification();
        } else {
          console.log("Not completed");
        }
      });
    },
    logout() {
      let _self = this;
      this.$axios.post("logout").then((res) => {
        if (res.data.success) {
          this.setSearch({
            search: "",
          });
          localStorage.removeItem("user");
          localStorage.removeItem("employee_page_no");
          localStorage.removeItem("project_page_no");

          _self.$router.push({ path: "/login" });
        } else {
          console.log("something went wrong with logout");
        }
      });
    },
    callSyncProjects() {
      this.setSyncProjects({
        syncProjects: true,
      });
    },
    callSyncEmployeess() {
      this.setSyncEmployees({
        syncEmployees: true,
      });
    },
    callSyncProject() {
      this.setSyncProject({
        syncProject: true,
      });
    },
    updateProfilePic() {
      let user = JSON.parse(localStorage.getItem("user"));
      this.user.profile_url = user.profile_url;
    },
    handleSocketAddNotification(data) {
      this.notificationArray.unshift(data.notification[0]);
      this.unReadNotification = data.unreadcount;
    },
    fetchPriorities() {
      this.$axios.get("task_priority").then((response) => {
        const resData = response.data;

        if (resData.success) {
          this.setPriorities(resData.priority);
        }
      });
    },
    getHumanReadableDate(date) {
      if (date) {
        return moment(date).format("DD/MM/YYYY hh:mm A");
      }

      return "";
    },
    redirectToNotificationContent(notification) {
      // mark notification as readed
      if (notification.is_read == "0") {
        this.readNotification(notification);
      }

      const moduleIndetifier = notification.module;

      if (moduleIndetifier === "task") {
        const taskDetails = notification.task_details;
        if (taskDetails && taskDetails.list_details) {
          const projectId = taskDetails.list_details.project_id;
          const listId = taskDetails.list_id;
          const taskId = taskDetails.task_id;

          this.$router.push({
            path: `/projects/${projectId}/board?list_id=${listId}&task_id=${taskId}`,
          });
        }
      } else if (moduleIndetifier === "project") {
        const projectId = notification.content_key;

        this.$router.push({
          path: `/projects/${projectId}`,
        });
      }
    },
    handleWsLogout() {
      this.logout();
    },
  },
  mounted() {
    this.$ws.$on("inactive_status", this.handleWsLogout);
    this.user = JSON.parse(localStorage.getItem("user"));
    this.getNotification();
    this.$ws.$on("add_notification", this.handleSocketAddNotification);
    if (this.user.role == "employee") {
      this.items = [
        {
          icon: "mdi-home",
          text: "Dashboard",
          href: "/home",
        },
        {
          icon: "mdi-chart-box-outline",
          text: "Projects",
          href: "/projects",
        },
      ];
    }

    this.fetchPriorities();
  },
};
</script>

<style>
.nav-menu {
  text-transform: capitalize;
  font-weight: 400;
}

.nav-menu::before {
  background-color: unset !important;
  /* border-bottom: 2px solid #197ebd; */
}

.nav-menu.v-btn--active {
  color: var(--primary-color) !important;
  border-bottom: 2px solid var(--primary-color);
}

.nav-menu.v-btn--active svg path:not([fill="transparent"]) {
  fill: var(--primary-color) !important;
}

.nav-menu.v-btn--active svg path[fill="transparent"] {
  stroke: var(--primary-color) !important;
}

.svg-icon {
  fill: var(--primary-color);
}

.user-menu.menuable__content__active {
  top: 65px !important;
}

.poniter {
  cursor: pointer;
}

.loader {
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#notification-drawer .v-card__text {
  padding: 0px 4px !important;
}

#notification-drawer .v-card__actions {
  padding: 0px;
  margin: 0px;
}
</style>

<style scoped>
.notification-item {
  padding: 5px;
  margin-bottom: 2px;
  border-radius: 6px;
}

.notification-item:not(:last-child) {
  border-bottom: 1px solid var(--border-color) !important;
}

.notification-item.unreaded {
  background: #4483f715;
}
</style>
