<template>
  <svg :width="size" :height="size" :viewBox="`0 0 24 24`">
    <path
      d="M13.375 7.3H6.625M13.375 12.7H6.625M7.3 19H12.7C17.2 19 19 17.2 19 12.7V7.3C19 2.8 17.2 1 12.7 1H7.3C2.8 1 1 2.8 1 7.3V12.7C1 17.2 2.8 19 7.3 19Z"
      :stroke="color"
      stroke-width="1.2"
      stroke-linecap="round"
      stroke-linejoin="round"
      fill="transparent"
    />
  </svg>
</template>

<script>
import IconMixin from "../../mixins/IconMixin";

export default {
  mixins: [IconMixin],
};
</script>
