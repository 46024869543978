import Vue from "vue";

const userTopicSubscriptions = id => {
  console.log('userTopicSubscriptions', id)
  if (id) {
    let subscription = Vue.ws.socket.getSubscription(`user:${id}`);
    if (!subscription) {
      subscription = Vue.ws.subscribe(`user:${id}`);
    }
    let chatSubscription = Vue.ws.socket.getSubscription(`chat`);
    if (!chatSubscription) {
      chatSubscription = Vue.ws.subscribe(`chat`);
    }
    // subscription.on("add_task", data => {
    //   console.log('Hello (event handled in src/WsSubscriptions.js)', data)
    // });
  }
};

export default async () => {
  return new Promise((resolve) => {
    Vue.ws.disconnect()
    Vue.ws.connect({ wsDomain: process.env.VUE_APP_SOCKET_URL, jwtToken: localStorage.getItem('user_token')}, { path: 'adonis-ws', reconnectionAttempts: 300, reconnectionDelay: 5000 });
    Vue.ws.socket.on("open", () => {
      let user_data = JSON.parse(localStorage.getItem('user'));
      userTopicSubscriptions(user_data.id);
      resolve()
    });
    
    // FOR EXAMPLE you can observe for userId or another variable from Vuex
    // store.watch(
    //   () => store.getters.vgUserUid,
    //   async id => {
    //     if (id) {
    //       userTopicSubscriptions(id);
    //     }
    //   }
    // );
  })
};